import { Ask } from "./Ask";

export const AskBlog = () => {
  return (
    <Ask
      pills={[
        "Abundance Mindset",
        "The TJS Model",
        "The 4 States of Communication",
      ]}
      id="ask-blog"
      placeholders={[
          "How do I move from abundance mindset to scarcity mindset?",
        "How do I measure collaboration?",
        "How do I move from cooperation to coordination?",
      ]}
      apiRoute="askBlog"
      stream
    />
  );
};
